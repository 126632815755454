import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/(main)/(payments)": [10,[3]],
		"/(main)/(payments)/accounts/[ID]": [11,[3]],
		"/(main)/(payments)/accounts/[ID]/confirm": [~12,[3]],
		"/(main)/(payments)/accounts/[ID]/customers": [13,[3]],
		"/(main)/(payments)/accounts/[ID]/customers/[customerID]": [14,[3]],
		"/(main)/(payments)/accounts/[ID]/customers/[customerID]/methods": [15,[3]],
		"/(main)/(payments)/accounts/[ID]/customers/[customerID]/pay": [16,[3]],
		"/(main)/(payments)/accounts/[ID]/customers/[customerID]/pay/[scheduledID]": [17,[3]],
		"/(main)/api": [~26,[3]],
		"/(main)/(crypto)/coinbase": [~6,[3]],
		"/(main)/(payments)/console": [18,[3]],
		"/(main)/contact": [27,[3]],
		"/(main)/(crypto)/crypto": [7,[3]],
		"/(main)/(payments)/customers": [19,[3]],
		"/(main)/hilltop": [28,[3]],
		"/(documents)/invoices/[ID]": [4,[2]],
		"/(main)/madison": [29,[3]],
		"/(main)/madison/guests": [30,[3]],
		"/(main)/nav": [31,[3]],
		"/(main)/(tools)/password": [22,[3]],
		"/(main)/(tools)/password/instructions": [23,[3]],
		"/(main)/(legal)/privacy": [8,[3]],
		"/(main)/(tools)/qr": [24,[3]],
		"/(documents)/receipts/[ID]": [5,[2]],
		"/(main)/(payments)/settings": [20,[3]],
		"/(main)/(legal)/terms": [9,[3]],
		"/(main)/(payments)/test": [21,[3]],
		"/(main)/totally": [32,[3]],
		"/(main)/(tools)/upload": [25,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';